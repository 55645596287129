import MainService from '@/services/MainService';
import PipelineExecution from '@/models/pipelines/PipelineExecution';
import PipelineExecutionDetails from '@/models/pipelines/PipelineExecutionDetails';
import PipelineParameters from '@/models/pipelines/PipelineParameters';
import { deserialize, serialize } from 'typescript-json-serializer';

export default class PipelineService {
  private static mainURL = '/pipeline';

  public static listTypes(): Promise<string[]> {
    return MainService.get(this.mainURL + '/type').then((response) => {
      return response.data;
    });
  }

  public static executePipelines(parameters: PipelineParameters): Promise<PipelineExecutionDetails[]> {
    return MainService.post(this.mainURL + '/execution', JSON.stringify(serialize(parameters))).then((response) => {
      return response.data.map((item) => deserialize(item, PipelineExecutionDetails));
    });
  }

  public static getExecutionDetails(executionId: string): Promise<PipelineExecutionDetails> {
    return MainService.get(this.mainURL + '/execution/' + executionId).then((response) => {
      return deserialize(response.data, PipelineExecutionDetails);
    });
  }

  public static getExecutions(
    finished: boolean | null,
    modelId: string | null,
    projectId: number | null,
    pipelineType: string | null
  ): Promise<PipelineExecution[]> {
    return MainService.get(this.mainURL + '/execution', {
      params: { finished, modelId, projectId, pipelineType },
    }).then((response) => {
      return response.data.map((item) => deserialize(item, PipelineExecution));
    });
  }
}
