import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { deserializePipelineState, serializeEnum } from '@/serializer/EnumHelpers';
import { PipelineStateEnum } from '@/models/pipelines/PipelineStateEnum';
import { deserializeMap } from '@/serializer/MapHelper';

@Serializable()
export default class PipelineExecutionStep {
  @JsonProperty('executionId')
  private _executionId: string;
  @JsonProperty('index')
  private _index: number;
  @JsonProperty('type')
  private _type: string;
  @JsonProperty({
    name: 'state',
    onSerialize: serializeEnum,
    onDeserialize: deserializePipelineState,
  })
  private _state: PipelineStateEnum;
  @JsonProperty('started')
  private _started: Date | null;
  @JsonProperty('lastUpdated')
  private _lastUpdated: Date;
  @JsonProperty({
    name: 'parameters',
    onSerialize: JSON.stringify,
    onDeserialize: deserializeMap,
  })
  private _parameters: Map<string, string>;
  @JsonProperty('stepResults')
  private _stepResults: string[] | null;

  constructor(
    executionId: string,
    index: number,
    type: string,
    state: PipelineStateEnum,
    started: Date | null,
    lastUpdated: Date,
    parameters: Map<string, string>,
    stepResults: string[] | null
  ) {
    this._executionId = executionId;
    this._index = index;
    this._type = type;
    this._state = state;
    this._started = started;
    this._lastUpdated = lastUpdated;
    this._parameters = parameters;
    this._stepResults = stepResults;
  }

  get executionId(): string {
    return this._executionId;
  }

  set executionId(value: string) {
    this._executionId = value;
  }

  get index(): number {
    return this._index;
  }

  set index(value: number) {
    this._index = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get state(): PipelineStateEnum {
    return this._state;
  }

  set state(value: PipelineStateEnum) {
    this._state = value;
  }

  get started(): Date | null {
    return this._started;
  }

  set started(value: Date | null) {
    this._started = value;
  }

  get lastUpdated(): Date {
    return this._lastUpdated;
  }

  set lastUpdated(value: Date) {
    this._lastUpdated = value;
  }

  get parameters(): Map<string, string> {
    return this._parameters;
  }

  set parameters(value: Map<string, string>) {
    this._parameters = value;
  }

  get stepResults(): string[] | null {
    return this._stepResults;
  }

  set stepResults(value: string[] | null) {
    this._stepResults = value;
  }
}
