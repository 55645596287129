
































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import PipelineState from '@/components/pipelines/PipelineState.vue';
import PipelineService from '@/services/PipelineService';
import moment from 'moment';
import PipelineExecutionDetails from '@/models/pipelines/PipelineExecutionDetails';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import SidebarLeft from '@/components/review/SidebarLeft.vue';

@Component({
  components: {
    PipelineState,
    SidebarLeft,
  },
})
export default class PipelineExecutionDetailsView extends mixins(Toasts) {
  @Prop({ required: true })
  protected readonly projectId!: string;
  @Prop({ required: true })
  protected readonly modelId!: string;
  @Prop({ required: true })
  protected readonly executionId!: string;

  protected executionDetails: PipelineExecutionDetails | null = null;

  protected showLeftSidebar = true;

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Project', to: '/project/details/' + this.projectId },
    { text: 'Model', to: '/project/editor/' + this.projectId + '/' + this.modelId },
    {
      text: 'Pipeline Executions',
      to: '/project/' + this.projectId + '/model/' + this.modelId + '/pipelines/executions',
    },
    { text: 'Details', active: true },
  ];

  mounted(): void {
    this.loadExecutionDetails();
  }

  @Watch('executionId')
  protected handleExecutionIdChanged(): void {
    this.loadExecutionDetails();
  }

  protected loadExecutionDetails(): void {
    PipelineService.getExecutionDetails(this.executionId)
      .then((executionDetails) => {
        this.executionDetails = executionDetails;
      })
      .catch((backendError) => {
        this.$router.replace({ name: '/' }).then(() => {
          this.showToast('Unknown Error', 'Oops, something failed: ' + backendError.response.status, 'danger');
        });
      });
  }

  protected formatTimestamp(date: Date | null): string {
    if (date == null) {
      return '-';
    }
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  }

  protected syncLeftSidebarVisibility(visible: boolean): void {
    this.showLeftSidebar = visible;
  }
}
