import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { deserializePipelineState, serializeEnum } from '@/serializer/EnumHelpers';
import { PipelineStateEnum } from '@/models/pipelines/PipelineStateEnum';
import PipelineExecutionStep from '@/models/pipelines/PipelineExecutionStep';

@Serializable()
export default class PipelineExecutionDetails {
  @JsonProperty('executionId')
  private readonly _executionId: string;
  @JsonProperty('pipelineType')
  private readonly _pipelineType: string;
  @JsonProperty('modelId')
  private readonly _modelId: string;
  @JsonProperty({
    name: 'state',
    onSerialize: serializeEnum,
    onDeserialize: deserializePipelineState,
  })
  private readonly _state: PipelineStateEnum;
  @JsonProperty('started')
  private readonly _started: Date;
  @JsonProperty('lastUpdated')
  private readonly _lastUpdated: Date;
  @JsonProperty('results')
  private readonly _results: string[] | null;
  @JsonProperty({
    name: 'steps',
    type: PipelineExecutionStep,
  })
  private readonly _steps: PipelineExecutionStep[];

  constructor(
    executionId: string,
    pipelineType: string,
    modelId: string,
    state: PipelineStateEnum,
    started: Date,
    lastUpdated: Date,
    results: string[] | null,
    steps: PipelineExecutionStep[]
  ) {
    this._executionId = executionId;
    this._pipelineType = pipelineType;
    this._modelId = modelId;
    this._state = state;
    this._started = started;
    this._lastUpdated = lastUpdated;
    this._results = results;
    this._steps = steps;
  }

  get executionId(): string {
    return this._executionId;
  }

  get pipelineType(): string {
    return this._pipelineType;
  }

  get modelId(): string {
    return this._modelId;
  }

  get state(): PipelineStateEnum {
    return this._state;
  }

  get started(): Date {
    return this._started;
  }

  get lastUpdated(): Date {
    return this._lastUpdated;
  }

  get results(): string[] | null {
    return this._results;
  }

  get steps(): PipelineExecutionStep[] {
    return this._steps;
  }
}
