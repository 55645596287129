














import { Prop } from 'vue-property-decorator';
import { PipelineStateEnum } from '@/models/pipelines/PipelineStateEnum';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({})
export default class PipelineState extends Vue {
  @Prop({ required: true })
  protected state!: PipelineStateEnum;

  protected stateClass(state: string): string {
    switch (state) {
      case 'PENDING':
        return 'pending';
      case 'RUNNING':
        return 'running';
      case 'RESUMING':
        return 'resuming';
      case 'FINISHED':
        return 'finished';
      case 'FAILED':
        return 'failed';
      case 'CANCELED':
        return 'canceled';
      default:
        return '';
    }
  }

  protected handleClick(event: Event): void {
    event.preventDefault();
    this.$emit('click');
  }
}
