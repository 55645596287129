// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeMap = (value: any): Map<string, string> => {
  const map = new Map<string, string>();
  for (const key in value) {
    map.set(key, value[key]);
  }
  return map;
};

export { deserializeMap };
